<template>
  <div>
    <profileParts />
    <SMTPform v-if="!email" :callback="setEmail" />
    <currentEmail v-else :email="email" :callback="setEmail" />
  </div>
</template>
<script>
export default {
  components: {
    profileParts () { return import('@/components/profile/parts') },
    SMTPform () { return import('./form') },
    currentEmail () { return import('./current') }
  },
  props: {},
  data () {
    return {
      variables: this.$getUserVariables(),
      email: false
    }
  },
  computed: {

  },
  mounted () {
    this.getData()
  },
  methods: {
    setEmail (ret) {
      this.email = ret
    },
    getData () {
      this.$flix_post({
        url: 'user_smtp/get',
        data: {
          user: this.variables.user.md5_id
        },
        callback: function (ret) { if (ret.data[0]) { this.email = ret.data[1] } }.bind(this)
      })
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
